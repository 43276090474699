<template>
  <div class="statistic">
    <div class="loader-center" v-if="pending">
      <LoaderCircular />
    </div>
    <div v-else>
      <div class="block">Всего анкет: {{ totals }}</div>
      <div class="block" v-for="stats in usersStats" :key="stats">
        <div class="statistic-username">
          <h4>{{ stats.displayName }}</h4>
          <span>({{ stats.points.length }})</span>
        </div>
        <table v-if="stats.points.length > 0">
          <tbody>
            <tr v-for="point in stats.points" :key="point">
              <td class="statistic-table-column-time">
                {{
                  new Date(point.datetime).toLocaleTimeString("ru", {
                    timeZone: "UTC",
                  })
                }}
              </td>
              <td class="statistic-table-column-point">
                <div
                  v-if="point.isOpened === false"
                  class="statistic-point-title-disabled"
                >
                  {{ point.pointTitle + " (не работает)" }}
                </div>
                <div
                  v-else-if="point.auditAllowed === false"
                  class="statistic-point-title-disabled"
                >
                  {{ point.pointTitle + " (аудит не разрешен)" }}
                </div>
                <div v-else class="statistic-point-title">
                  {{ point.pointTitle }}
                </div>
                <div>{{ point.pointAddress }}</div>
                <div class="statistic-form-type">{{ point.formTypeTitle }}</div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else>Нет данных</div>
      </div>
      <!-- error -->
      <div class="error" v-if="errorMessage">
        {{ errorMessage }}
      </div>
      <button class="statistic-refresh-btn" @click="handleUpdate">
        Обновить
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { analytics } from "@/firebase/config";
import useApi from "@/composables/useApi";
import firebaseCredential from "@/composables/firebaseCredential";
import LoaderCircular from "@/components/LoaderCircular";
export default {
  components: {
    LoaderCircular,
  },
  setup() {
    const { getStatistic } = useApi();
    const { user } = firebaseCredential();
    const pending = ref(false);
    const usersStats = ref(null);
    const totals = ref(null);
    const errorMessage = ref(null);
    const handleUpdate = async () => {
      analytics.logEvent("get-statistic", { userId: user.value.uid });
      pending.value = true;
      errorMessage.value = null;
      totals.value = null;
      getStatistic().then(
        (res) => {
          usersStats.value = res.result;
          totals.value = 0;
          for (let i in usersStats.value) {
            totals.value += usersStats.value[i].points.length;
          }
          pending.value = false;
        },
        (reason) => {
          errorMessage.value = reason.message;
          pending.value = false;
        }
      );
    };
    return {
      pending,
      usersStats,
      totals,
      handleUpdate,
      errorMessage,
    };
  },
  mounted() {
    this.handleUpdate();
  },
};
</script>

<style></style>
